<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <div class="min-heigth-61vh">
        <b-row>
          <b-col cols="12">
            <ProfileLine :title="`${numberModel} Modelo(s) - ${brand.name}`" :items="profileItems" />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-table 
              class="table-rank"
              striped hover 
              :busy="tableBusy"
              :items="items" 
              :fields="fields"
              show-empty
              empty-text="Sem Nenhum Aluno Cadastrado"
            >
                <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Carregando...</strong>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button variant="warning" class="mr-2" @click="edit(row.item.id)">
                  <i class="fa fa-pencil"></i>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import BrandService from '@/service/BrandService'

import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ProfileLine from '@/components/ProfileLine'

export default {
  components: { Header, Footer, ProfileLine },

  data () {
    return {
      profileItems: [
        {
          text: 'Admin',
          href: '/admin'
        },
        {
          text: 'Marcas',
          href: '/admin/brand'
        },
        {
          text: 'Item',
          active: true
        }
      ],
      brandService: null,
      brand: {},
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'description', label: 'Descrição', sortable: true },
        { key: 'price', label: 'Preço', sortable: true },
        { key: 'actions', label: 'Ações', thClass: 'th-action' }
      ],
      items: [],
      numberModel: 0,
      tableBusy: false
    }
  },
  methods: {
    getBrandPriceByBrandId (brandId, numberModel) {
      this.tableBusy = true
      this.brandService.getBrandPriceByBrandId(brandId, numberModel)
        .then(response => {
          this.items = response
          this.tableBusy = false
        })
    },
    getBrand (brandId) {
      this.tableBusy = true
      this.brandService.get(brandId)
        .then(response => {
          this.brand = response
        })
    },
    edit (id) {
      this.$router.push(`/admin/brand/price/${id}`) 
    }
  },
  mounted () {
    this.brandService = new BrandService()
    this.numberModel = this.$route.params.numberModel
    this.getBrand(this.$route.params.id)
    this.getBrandPriceByBrandId(this.$route.params.id, this.$route.params.numberModel)
  }
}
</script>

<style>
  .min-heigth-61vh {
    min-height: 61vh;
  }

  body {
    background: #F5F5F5;
  }
</style>